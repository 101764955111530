<template>
  <a :href="link" class="btn">
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: "Button",
  props: ['link']
}
</script>

<style scoped>
.btn {
  padding: 13px 25px;
  border-radius: 8px;
  background: var(--bg-veresk);
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
  font-size: 1em;
  display: inline-block;
}
@media (max-width: 420px) {
  .btn{
    font-size: 0.8em;
    padding: 10px 20px;
  }
}
</style>