<template>
  <div class="content">
    <div class="first"></div>
    <div class="text">
      <h2>Где кататься на вейкборде в Санкт-Петербурге?</h2>
      <p>Конечно, на озере Большое Симагинское – одном из самых спокойных и красивых в Ленинградской области!
      </p>
      <p>Клуб Take Off Wake приглашает всех поклонников вейкборда – взрослых и детей, новичков и опытных райдеров – в крутой вейк-парк под СПб!</p>
      <p>
        <Button link="https://takeoffwake.ru">Забронировать лебедку</Button>
      </p>
    </div>
    <div><img src="../assets/images/photos/wake1.jpg" alt=""></div>
    <div class="text">
      <h2>Услуги школы вейкбординга</h2>
      <ul>
        <li>обучение вейкбордингу для взрослых и детей любого уровня подготовки</li>
        <li>SUP-серфинг (сап-серфинг): водные прогулки, аренда снаряжения для самостоятельного</li>
        <li>летний дневной лагерь для детей</li>
        <li>детская секция вейкбординга</li>
        <li>организация мероприятий на любой вкус: дней рождения, праздников, тимбилдингов и корпоративов</li>
      </ul>
      <p>
        <Button link="https://takeoffwake.ru/shkola-wakebordinga">Подробнее</Button>
      </p>
    </div>
    <div>
      <img src="../assets/images/photos/wake2.jpg" alt="">
    </div>
    <div class="second"></div>

    <div class="text">
      <h2>SUP-серфинг в эко-парке "Вереск"</h2>
      <p>SUP (сап-серфинг, сапсерфинг) – разновидность серфинга, отличающаяся от классики тем, что райдер управляет скольжением доски по волнам не руками и собственным телом,
        а специальным веслом.</p>
      <p>
        SUP-серфинг еще достаточно молод, ведь в отдельное направление он выделился сравнительно недавно – лишь в начале этого века. Однако с каждым годом у него появляется
        все больше поклонников и его популярность растет. Это связано с тем, что сап серфинг универсален: он подходит и для любителей экстрима, и для сторонников спокойного
        времяпрепровождения, для детей и для взрослых, для мужчин и женщин. Серфинг на сап-бордах – прекрасный вид активного отдыха, который помогает поддерживать физическую
        форму и дарит ни с чем не сравнимые ощущения.</p>
      <p>
        Школа Take Off Wake Club предлагает обучение SUP-серфингу в одной из лучших локаций в Ленинградской области.
      </p>
      <ul>
        <li>обучение сап-серфингу у профессиональных тренеров</li>
        <li>групповые и индивидуальные прогулки по озеру на сап-досках</li>
        <li>организация любительских соревнований</li>
        <li>подготовка и проведение корпоративов, тимбилдингов, праздничных и других мероприятий</li>
        <li>почасовая и посуточная аренда</li>
      </ul>
      <p></p>
      <p>
        Арендовать SUP-борд можно всего за <strong>500 руб./час.</strong> При этом инструктаж проводится бесплатно.
        В парке 10 сапов, поэтому вы можете приезжать компанией.
      </p>
      <p>
        <Button link="https://takeoffwake.ru">Забронировать онлайн</Button>
      </p>
    </div>
    <div class="sup"></div>
    <div class="full">
      <div id="map"></div>
      <div class="contact">
        <img src="../assets/images/logos/logo-veresk-gray.svg">
        <div>
          <h3><a tel="+7 (931) 585-03-93">+7 (931) 585-03-93</a></h3>
          <p>работаем c 11:00 до 20:00</p>
        </div>
      </div>
    </div>
    <div class="text full">
      <h2>Как добраться до эко-парка «Вереск»?</h2>
      <p>
        Проще всего на личном авто: ищите в Яндекс.Навигаторе «ресторан Вереск».
      </p>
      <p> Если вы без авто, цельтесь на Зеленогорск.
        Добраться можно любым удобным способом (мы советуем «Ласточку» от Финляндского вокзала — быстрее всего).
        От вокзала Зеленогорска прямо к эко-парку доставят 410 и 415 автобус (нужная остановка — «озеро Красавица»).
      </p>
      <p>
        От парковки ресторана следуйте правее под шлагбаум — на территорию эко-парка «Вереск». На перекрестке перед указателями сворачивайте направо на пляж. 300м вперед — и
        вы на месте сбора!
      </p>
    </div>
    <div class="full no-bg no-radius">
      <Gallery></Gallery>
    </div>
  </div>
</template>
<script>

import Gallery from "@/components/Gallery";
import Button from "@/components/Button";

export default {
  name: "Content",
  components: {Button, Gallery},
}
</script>

<style scoped>
.content {
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: 4fr 3fr;
  justify-items: stretch;
}

.content div {
  border-radius: var(--radius);
  background: #f6f6f6;
  overflow: hidden;
  /*object-fit: cover;*/
}

.content div img {
  width: 100%;
  display: flex;
  object-fit: scale-down;
  object-position: center;
}

div.first {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  background: url("../assets/images/photos/wake5.jpg") center / cover no-repeat;
  display: flex;
}


div.sup {
  background: url("../assets/images/photos/sup.jpg") center / cover no-repeat;
}

div.second {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: span 2;
  background: url("../assets/images/photos/wake6.jpg") center / cover no-repeat;
  /*display: flex;*/
}

div.full {
  grid-column: span 2;
  position: relative;
}

.text {
  padding: 20px;
}

.text h2 {
  font-size: 2em;
  margin-bottom: 1em;
}

#map {
  margin: var(--radius);
  height: 440px;
  position: relative;
}

.content .full.no-bg div,
.content .full.no-bg {
  background: unset;
}

div.full .contact {
  --margin: 20px;
  position: absolute;
  z-index: 9999;
  left: 0;
  top: 0;
  height: calc(100% - var(--margin) * 2);
  width: 38%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: var(--margin);
  padding: calc(var(--margin) + 20px);
  border-radius: unset;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(10px);
}

div.full .contact * {
  border-radius: unset;
  text-align: center;
  background: unset;
}

div.full .contact h3 {
  font-weight: normal;
  font-size: 2em;
}

div.full .contact a,
div.full .contact p {
  font-weight: 300;
  color: gray;
  text-decoration: none;
}

ul {
  margin-bottom: 1em;
}

div *:last-child {
  margin-bottom: 0;
}

#map > ymaps {
  border-radius: 20px;
  overflow: hidden;
}

div.no-radius {
  border-radius: unset;
}

@media (max-width: 800px) {
  .content {
    grid-template-columns: 3fr 3fr;
  }
}

@media (max-width: 420px) {
  .content {
    grid-template-columns: 1fr;
  }

  .text h2 {
    font-size: 1.4em;
  }

  div.first {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 1;
    height: 50vh;
  }

  div.second {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 7;
    grid-row-end: 7;
    height: 30vh;
  }

  div.sup {
    height: 30vh;
  }

  div.full .contact {
    box-sizing: border-box;
    padding: 2em;
    background: none;
    width: calc(100% - 2em);
    position: relative;
  }

  div.full .contact img {
    width: 100%;
    margin: 0;
  }

  div.full .contact {
    font-size: 15px;
  }

  div.full {
    grid-column: unset;
  }

  #map {
    height: 50vh;
  }
}
</style>