<template>
  <!--  <h1>Вейк парк Take Off</h1>-->
  <div class="bg" ref="container">
    <img src="../assets/images/photos/title.jpg" alt="Ваке Парк Take off в эко-парке Вереск">
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
h1 {

  font-size: 7.4vw;
  /*font-family: 'Gill Sans';*/
  font-weight: 600;
  line-height: 0.7;
}

.bg {
  position: relative; /*aspect ratio*/
  padding-top: 8.65%; /*aspect ratio*/
  mask-image: url('../assets/images/tittle.svg');
  mask-clip: border-box;
  mask-size: contain;
  mask-repeat: no-repeat;
  overflow: hidden;
  max-height: 110px;
}

img, svg {
  position: absolute; /*aspect ratio*/
  left: 0; /*aspect ratio*/
  top: 0; /*aspect ratio*/
  width: 100%; /*aspect ratio*/
  height: auto; /*aspect ratio*/
}

</style>